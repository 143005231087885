import React from "react";
import Marquee from "react-fast-marquee";

const MarqueeComponent = ({ assets }) => {
  return (
    <div className="marquee-container">
      <h1>Fueling competitive advantage of our clients</h1>
      <Marquee
        autoFill
        className="overflow-hidden"
        direction="left"
        pauseOnHover
        speed={30}
        style={{ overflow: "hidden", paddingBlock: '50px' }}
      >
        {assets.map((logo, index) => (
          <img key={index} src={logo} alt="logo" />
        ))}
      </Marquee>
    </div>
  );
};

export default MarqueeComponent;
