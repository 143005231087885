import React from "react";

const ContactUsButton = ({
  className,
  linkOnly = false,
  text = "Contact Us",
  link = "#ContactForm",
  isLightText = true
}) => {
  return linkOnly ? (
    <a href={link}>
      {text} <i className="fa-solid fa-arrow-right"></i>
    </a>
  ) : (
    <div className={`lead-form-container ${className}`}>
      <a className={`contact-form-link ${isLightText ? 'text-white' : 'text-dark'}`} href={link}>
        {text} <i className="fa-solid fa-arrow-right"></i>
      </a>
    </div>
  );
};

export default ContactUsButton;
