import React, { useEffect } from "react";
import AOS from "aos";
import page_json from "./config/config.json";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";
import ContactUsButton from "./ContactUsButton";
import MarqueeComponent from "./MarqueeComponent";

const textDataUrl = page_json.page_jsons.TechTalent;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function TechTalent() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  return (
    <div data-aos="fade-up" className="techTalentPage">
      <Helmet>
        <meta
          name="description"
          content="Igniting innovation & growth for your enterprise"
        />
        <title>Staffing | Services | Engineersmind</title>
      </Helmet>
      <div className="container-fluid m-0 p-0 brd-crmb">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/Services/Digital-Transformation">Services </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Staffing
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0">
        <div className="inner-banner">
          <img
            src={textData.layer1.img}
            className="d-block w-100"
            alt="banner-img"
          />
          <div className="inner-banner-text tech-talent">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                  <h1 className="animate__animated animate__fadeInLeft">
                    <span>{textData.layer1.heading.text1}</span>
                    {textData.layer1.heading.text2}
                    <br /> <span>{textData.layer1.heading.text3}</span>{" "}
                    {textData.layer1.heading.text4}
                  </h1>
                  <p data-aos="zoom-in" data-aos-duration="1000">
                    {textData.layer1.body}
                  </p>
                  <ContactUsButton className="light mt-3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container full-height-section">
        <div className="container">
          <div className="row sub-header-row first-sub-header-section">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="content-container d-flex flex-column justify-content-center h-100">
                <h4 className="mb-4">{textData.subHeroSection.heading}</h4>
                <p>{textData.subHeroSection.description1}</p>
                <p className="mt-3">{textData.subHeroSection.description2}</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 d-flex align-items-center">
              <div className="image-container d-flex justify-content-center">
                <img
                  src={textData.subHeroSection.img}
                  className="img-fluid"
                  alt="industry"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container full-height-section">
        <div className="container">
          <div className="row sub-header-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 d-flex">
              <div className="d-flex">
                <img
                  src={textData.ourClientsSection.img}
                  className="img-fluid align-self-center"
                  alt="industry"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="d-flex flex-column justify-content-center h-100">
                <h4 className="mb-4">{textData.ourClientsSection.heading}</h4>
                <p>{textData.ourClientsSection.description1}</p>
                <p className="mt-3">
                  {textData.ourClientsSection.description2}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="alliances d-flex flex-column justify-content-center align-items-center">
        <h1 className="text-white text-center">
          {textData.layer5.heading.text1}
          <span>{textData.layer5.heading.text2}</span>
          {textData.layer5.heading.text3}
        </h1>
        <ContactUsButton
          text={textData.layer5.button.text}
          link={textData.layer5.button.url}
          className="light"
        />
      </div>

      <div className="container full-height-section">
        <div className="row">
          <h1>
            <span>{textData.ourTalentPoolSection.heading}</span>
          </h1>
          <p className="text-20 mb-4">
            {textData.ourTalentPoolSection.description}
          </p>
          <div className="row portal ourTalentPool">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              {textData.ourTalentPoolSection.points
                .slice(0, 5)
                .map((point, index) => {
                  return (
                    <div className="d-flex w-100 list-item pb-3" key={index}>
                      <img src={point.img} alt="bullet-point" />
                      <div>
                        <h4 className="mb-2" style={{
                          cursor: point?.link ? 'pointer' : 'unset'
                        }} onClick={() => point?.link && window.open(point?.link)}>{point.title}</h4>
                        <p className="portal-desc text-20">
                          {point.description}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              {textData.ourTalentPoolSection.points
                .slice(5, 9)
                .map((point, index) => {
                  return (
                    <div className="d-flex w-100 list-item pb-3" key={index}>
                      <img src={point.img} alt="bullet-point" />
                      <div>
                        <h4 className="mb-2">{point.title}</h4>
                        <p className="portal-desc text-20">
                          {point.description}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid matrix liferaypage">
        <div className="container">
          <h1>{textData.hiringStructureSection.heading}</h1>
          <p>{textData.hiringStructureSection.heading_details}</p>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 hiringStructureSection">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="UA"
                  role="tabpanel"
                  aria-labelledby="unified"
                  data-is-left-layout={true}
                >
                  <div className="tab-content-detailsflex">
                    <div className="tab-content-details col-lg-8 col-md-12 col-sm-12 col-xs-12">
                      <h4>
                        {textData.hiringStructureSection.details.data1.heading}
                      </h4>
                      <p>
                        {textData.hiringStructureSection.details.data1.body}
                      </p>
                    </div>
                    <div className="tab-content-img col-lg-4 col-md-12 col-sm-12 col-xs-12">
                      <img
                        src={textData.hiringStructureSection.details.data1.img}
                        className="d-block w-100"
                        alt="banner-img"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show active"
                  id="SDI"
                  role="tabpanel"
                  aria-labelledby="streamlined"
                >
                  <div className="tab-content-detailsflex">
                    <div className="tab-content-details col-lg-8 col-md-12 col-sm-12 col-xs-12">
                      <h4>
                        {textData.hiringStructureSection.details.data2.heading}
                      </h4>
                      <p>
                        {textData.hiringStructureSection.details.data2.body}
                      </p>
                    </div>
                    <div className="tab-content-img col-lg-4 col-md-12 col-sm-12 col-xs-12">
                      <img
                        src={textData.hiringStructureSection.details.data2.img}
                        className="d-block w-100"
                        alt="banner-img"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show active"
                  id="SDW"
                  role="tabpanel"
                  aria-labelledby="scalabale"
                  data-is-left-layout={true}
                >
                  <div className="tab-content-detailsflex">
                    <div className="tab-content-details col-lg-8 col-md-12 col-sm-12 col-xs-12">
                      <h4>
                        {textData.hiringStructureSection.details.data3.heading}
                      </h4>
                      <p>
                        {textData.hiringStructureSection.details.data3.body}
                      </p>
                    </div>
                    <div className="tab-content-img col-lg-4 col-md-12 col-sm-12 col-xs-12">
                      <img
                        src={textData.hiringStructureSection.details.data3.img}
                        className="d-block w-100"
                        alt="banner-img"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show active"
                  id="DEV"
                  role="tabpanel"
                  aria-labelledby="visualization"
                >
                  <div className="tab-content-detailsflex">
                    <div className="tab-content-details col-lg-8 col-md-12 col-sm-12 col-xs-12">
                      <h4>
                        {textData.hiringStructureSection.details.data4.heading}
                      </h4>
                      <p>
                        {textData.hiringStructureSection.details.data4.body}
                      </p>
                    </div>
                    <div className="tab-content-img col-lg-4 col-md-12 col-sm-12 col-xs-12">
                      <img
                        src={textData.hiringStructureSection.details.data4.img}
                        className="d-block w-100"
                        alt="banner-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container full-height-section">
        <h1 className="mb-5">{textData.staffingServicesSection.heading}</h1>
        <div className="row">
          {textData.staffingServicesSection.points.map((point, index) => {
            return (
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-4 staffing-card">
                <div className="d-flex flex-column inner-container align-items-center">
                  <img
                    src={point.img}
                    className="d-block mt-2 mb-4"
                    alt="staffing-services-img"
                  />

                  <div className="inner-container-content  d-flex flex-column">
                    <h4>{point.heading}</h4>
                    <p className="mt-2 text-center">{point.description}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="container full-height-section">
        <h1>{textData.whatWeDo.heading}</h1>
        <div className="row portal whatWeDoSection">
          {textData.whatWeDo.points.map((point, index) => {
            return (
              <div className="d-flex w-100 list-item  pb-3" key={index}>
                <img src={point.img} alt="bullet-point" />
                <div>
                  <h4 className="mb-2">{point.title}</h4>
                  <p className="portal-desc text-20">{point.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="container full-height-section">
        <h1>{textData.recruitingSection.heading}</h1>
        <p className="text-20 mb-5">{textData.recruitingSection.description}</p>
        {textData.recruitingSection.steps.map((step, index) => {
          return (
            <div className="process-step">
              <img src={step.img} alt="step-img" className="step-image-view" />
              <div className="text-line">
                <div className="divider" />
                <span>Step {index + 1}</span>
              </div>
              <div>
                <span className="text-line-mobile">Step {index + 1}</span>
                <h4>{step.title}</h4>
                <p>{step.description}</p>
              </div>
            </div>
          );
        })}
      </div>

      <div className="container-fluid testi-home full-height-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <h1 className="mb-5">{textData.testimonials.heading}</h1>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
              <div id="carouselExampleCaptions" className="carousel slide">
                <div className="carousel-inner">
                  {textData.testimonials.editable_carousel.map((item, index) => {
                    if (index === 0) {
                      return (
                        <div
                          className={"carousel-item carousel-item-"
                            .concat(String.toString(index + 1))
                            .concat(" active")}
                        >
                          <div>
                            <p className="main-text">
                              <i className="fa-solid fa-quote-left"></i>
                              {item.testimony}
                              <i className="fa-solid fa-quote-right"></i>
                            </p>
                            &nbsp;
                            <p className="bottom-text">
                              <b>{item.name}</b>
                              <br />
                              <i>{item.post}</i>
                            </p>
                            <p className="morecss">
                              <a href={item.button.link}>
                                {item.button.text}{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                              </a>
                            </p>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className={"carousel-item carousel-item-".concat(
                            String.toString(index + 1)
                          )}
                        >
                          <div>
                            <p className="main-text">
                              <i className="fa-solid fa-quote-left"></i>
                              {item.testimony}
                              <i className="fa-solid fa-quote-right"></i>
                            </p>
                            &nbsp;
                            <p className="bottom-text">
                              <b>{item.name}</b>
                              <br />
                              <i>{item.post}</i>
                            </p>
                            <p className="morecss">
                              <a href={item.button.link}>
                                {item.button.text}{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                              </a>
                            </p>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="buttoncss">
                  <div className="control-btn-3">
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleCaptions"
                      data-bs-slide="next"
                    >
                      <i className="fa-solid fa-arrow-right"></i>
                      <span
                        className="carousel-control-next-icon visually-hidden"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleCaptions"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon visually-hidden"
                        aria-hidden="true"
                      ></span>
                      <i className="fa-solid fa-arrow-left"></i>
                      <span className="visually-hidden">Previous</span>
                    </button>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container full-height-section why-section">
        <h1>{textData.whySection.heading}</h1>
        {textData.whySection.points.map((point, index) => {
          return (
            <div className="d-flex w-100 list-item mb-2" key={index}>
              <img src={textData.bulletPoints.img} alt="bullet-point" />
              <div>
                <h4 className="text-20">
                  <strong>{point.title}</strong>
                </h4>
                <p className="portal-desc text-20">{point.description}</p>
              </div>
            </div>
          );
        })}
      </div>

      <div className="container full-height-section spacer-bottom">
        <h1>{textData.getStarted.heading}</h1>
        <p className="text-20">{textData.getStarted.description1}</p>
        <p
          className="mt-3 text-20"
          dangerouslySetInnerHTML={{ __html: textData.getStarted.description2 }}
        />
        <p className="mt-3 text-20">{textData.getStarted.description3}</p>
      </div>

      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
            <div className="boosting">
              <h1>{textData.layer3.heading}</h1>
              <p className="boostingsub">{textData.layer3.body1}</p>
              <p className="boostingsub">
                {textData.layer3.body2.text1}
                <br /> {textData.layer3.body2.text2}
              </p>
              <a href={textData.layer3.button.url}>
                {textData.layer3.button.text}{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
            <div className="boosting-img">
              <img
                src={textData.layer3.button.img}
                className="d-block w-100"
                alt="banner-img"
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="container-fluid talent-offer">
        <div className="container">
          <h1>
            <span> {textData.layer2.heading}</span>
          </h1>
          <p>{textData.layer2.body}</p>

          <div className="row">
            {textData.layer2.editable_data.map((item, index) => {
              return (
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <div className="module-border-wrap">
                    <div className="module">
                      <p id="pdng">{item}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div> */}

      <MarqueeComponent assets={textData.clients} />

      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      
      {/* <div className="container-fluid tabg alliance">
        <div className="TA-bg">
          <div className="container">
            <div className="row">
              <h1>{textData.layer6.heading}</h1>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img
                  src={textData.layer6.img}
                  alt="logos"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default TechTalent;
